import axios from 'axios'
//导入
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// import { Message, Loading } from 'element-ui';

//定义loading变量
let loading        

//使用Element loading-start 方法
// function startLoading() {    
//     loading = Loading.service({
//         lock: true,
//         text: '加载中……',
//         background: "rgba(0, 0, 0, 0.8)"
//     })
// }
// //使用Element loading-close 方法
// function endLoading() {    
//     loading.close()
// }

//那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
//声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
//调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0
export function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        // startLoading()
		NProgress.start()
    }
    needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
		needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        // endLoading()
		NProgress.done()
    }
}
const request = axios.create({
	//baseURL: 'http://120.79.245.4/visual/machine',//请求的url
	// baseURL: 'http://192.168.1.100/visual',//请求的url
	baseURL: '/visual/machine/',//请求的url
	timeout: 10000     //访问超时的时间限制
})

//http request 拦截器
request.interceptors.request.use(
    config => {
      showFullScreenLoading()
      return config;
    },
    error => {
        return Promise.reject(err);
    }
);


//http response 拦截器
request.interceptors.response.use(
    response => {
        tryHideFullScreenLoading()
        return response;
    },
    error => {
        return Promise.reject(error)
    }
)

export default request
